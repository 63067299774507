<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 31.9999L2.24933 23.7826C0.861331 21.3773 0.132 18.6506 0.133333 15.8546C0.137333 7.11332 7.25065 0 15.9906 0C20.232 0.00133333 24.2133 1.65333 27.2079 4.65066C30.2013 7.64798 31.8493 11.632 31.8479 15.8693C31.8439 24.6119 24.7306 31.7253 15.9906 31.7253C13.3373 31.7239 10.7226 31.0586 8.40665 29.7946L0 31.9999ZM8.79598 26.9239C11.0306 28.2506 13.164 29.0453 15.9853 29.0466C23.2493 29.0466 29.1666 23.1346 29.1706 15.8666C29.1733 8.58398 23.2839 2.67999 15.996 2.67733C8.72665 2.67733 2.81333 8.58931 2.81066 15.856C2.80933 18.8226 3.67866 21.044 5.13866 23.3679L3.80666 28.2319L8.79598 26.9239ZM23.9786 19.6386C23.8799 19.4733 23.6159 19.3746 23.2186 19.176C22.8226 18.9773 20.8746 18.0186 20.5106 17.8866C20.148 17.7546 19.884 17.688 19.6186 18.0853C19.3546 18.4813 18.5946 19.3746 18.364 19.6386C18.1333 19.9026 17.9013 19.936 17.5053 19.7373C17.1093 19.5386 15.832 19.1213 14.3186 17.7706C13.1413 16.72 12.3453 15.4226 12.1146 15.0253C11.884 14.6293 12.0906 14.4146 12.288 14.2173C12.4666 14.04 12.684 13.7546 12.8826 13.5226C13.084 13.2933 13.1493 13.128 13.2826 12.8626C13.4146 12.5986 13.3493 12.3666 13.2493 12.168C13.1493 11.9706 12.3573 10.02 12.028 9.22665C11.7053 8.45465 11.3786 8.55865 11.136 8.54665L10.376 8.53331C10.112 8.53331 9.68264 8.63198 9.31998 9.02931C8.95731 9.42665 7.93332 10.384 7.93332 12.3346C7.93332 14.2853 9.35331 16.1693 9.55064 16.4333C9.74931 16.6973 12.344 20.7 16.3186 22.416C17.264 22.8239 18.0026 23.0679 18.5773 23.2506C19.5266 23.5519 20.3906 23.5093 21.0733 23.4079C21.8346 23.2946 23.4173 22.4493 23.7479 21.524C24.0786 20.5973 24.0786 19.804 23.9786 19.6386Z"
      fill="white"
    />
  </svg>
</template>
