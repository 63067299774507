<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.2108 20.29L20.7908 21.71C20.6034 21.8962 20.35 22.0008 20.0858 22.0008C19.8216 22.0008 19.5682 21.8962 19.3808 21.71L7.50079 9.85C7.1756 9.94421 6.83931 9.99465 6.50079 10C5.8641 9.99955 5.23671 9.84711 4.67078 9.55536C4.10486 9.26362 3.61676 8.84099 3.24705 8.32263C2.87735 7.80426 2.63673 7.20513 2.5452 6.57505C2.45367 5.94496 2.51387 5.30213 2.72079 4.7L5.26079 7.24L5.79079 6.71L7.21079 5.29L7.74079 4.76L5.20079 2.22C5.80293 2.01307 6.44576 1.95287 7.07584 2.04441C7.70592 2.13594 8.30505 2.37656 8.82342 2.74626C9.34179 3.11596 9.76441 3.60407 10.0562 4.16999C10.3479 4.73591 10.5003 5.3633 10.5008 6C10.4954 6.33852 10.445 6.67481 10.3508 7L22.2108 18.88C22.397 19.0674 22.5016 19.3208 22.5016 19.585C22.5016 19.8492 22.397 20.1026 22.2108 20.29ZM2.79079 18.88C2.60454 19.0674 2.5 19.3208 2.5 19.585C2.5 19.8492 2.60454 20.1026 2.79079 20.29L4.21079 21.71C4.39816 21.8962 4.65161 22.0008 4.91579 22.0008C5.17998 22.0008 5.43343 21.8962 5.62079 21.71L11.0908 16.25L8.26079 13.42M20.5008 2L16.5008 4V6L14.3308 8.17L16.3308 10.17L18.5008 8H20.5008L22.5008 4L20.5008 2Z"
      fill="#4CAF50"
    />
  </svg>
</template>
