<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 1L3.5 5V11C3.5 16.55 7.34 21.74 12.5 23C17.66 21.74 21.5 16.55 21.5 11V5L12.5 1ZM12.5 3.18L19.5 6.3V11.22C19.5 12.92 19 14.65 18.15 16.17C16.5 14.94 13.76 14.5 12.5 14.5C11.24 14.5 8.5 14.94 6.85 16.17C6 14.65 5.5 12.92 5.5 11.22V6.3L12.5 3.18ZM12.5 6C11.5717 6 10.6815 6.36875 10.0251 7.02513C9.36875 7.6815 9 8.57174 9 9.5C9 10.4283 9.36875 11.3185 10.0251 11.9749C10.6815 12.6313 11.5717 13 12.5 13C13.4283 13 14.3185 12.6313 14.9749 11.9749C15.6313 11.3185 16 10.4283 16 9.5C16 8.57174 15.6313 7.6815 14.9749 7.02513C14.3185 6.36875 13.4283 6 12.5 6ZM12.5 8C12.8978 8 13.2794 8.15804 13.5607 8.43934C13.842 8.72064 14 9.10218 14 9.5C14 9.89782 13.842 10.2794 13.5607 10.5607C13.2794 10.842 12.8978 11 12.5 11C12.1022 11 11.7206 10.842 11.4393 10.5607C11.158 10.2794 11 9.89782 11 9.5C11 9.10218 11.158 8.72064 11.4393 8.43934C11.7206 8.15804 12.1022 8 12.5 8ZM12.5 16.5C14.07 16.5 16.14 17.11 17.03 17.84C15.79 19.38 14.2 20.55 12.5 21C10.8 20.55 9.21 19.38 7.97 17.84C8.87 17.11 10.93 16.5 12.5 16.5Z"
      fill="#4CAF50"
    />
  </svg>
</template>
